import React from 'react'
import { Container } from '@components'
import { ContactUsForm } from '@pages/components'

const ContactUs = () => {
  return (
    <section style={{ backgroundColor: '#F9F9F9', marginBottom: 25 }}>
      <Container>
        <ContactUsForm.ContactUsContent />
      </Container>
    </section>
  )
}

export default ContactUs
